import { getFilingDraftAuthors } from '../services/filingDraftService';
import { FILING_STATUS } from '../enums';

export const currentUser = (state) => state.user && state.user.data;

export const currentAccount = (state) => state.user
  && state.user.data
  && state.user.data.accountId;

export const getUserPreferences = (state) => {
  const user = state.user.data;
  return user?.preferences && user.preferences.ui;
};

export const hasWorkflowFeature = (state) => {
  const user = state.user.data;
  return user?.accountId?.features?.workflow;
};

export const hasCustomRolesFeature = (state) => {
  const user = state.user.data;
  return user?.accountId?.features?.customRoles;
};

export const hasResponseDraftSubmissionFeature = (state) => {
  const user = state.user.data;
  return user?.accountId?.features?.responseDrafts;
};

export const hasObjectionAiAssistFeature = (state) => {
  const user = state.user.data;
  return user?.accountId?.features?.aiObjectionsAssist;
};

export const hasAnalyticsFeature = (state) => {
  const user = state.user.data;
  return user?.accountId?.features?.analytics;
};

export const hasMozartFormsFeature = (state) => {
  const user = state.user.data;
  return user?.accountId?.features?.mozartForms;
};

export const hasWorkflowChatFeature = (state) => {
  const user = state.user.data;
  return user?.accountId?.features?.workflowChat;
};

export const hasFilingDraftsFeature = (state) => {
  const user = state.user.data;
  return user?.accountId?.features?.filingDrafts;
};

export const hasCanadianProvincesFeature = (state) => {
  const user = state.user.data;
  return user?.accountId?.features?.canadianProvinces;
};

export const hasRampDriveFeature = (state) => {
  const user = state.user.data;
  return user?.accountId?.features?.rampDrive;
};

export const hasProjectNumberGeneratorFeature = (state) => {
  const user = state.user.data;
  return user?.accountId?.features?.projectNumberGenerator;
};

export const hasProjectStatusFeature = (state) => {
  const user = state.user.data;
  return user?.accountId?.features?.projectStatuses;
};

export const getFilingBatch = (state) => {
  const { filingDraft } = state;
  const { batches, selectedBatch } = filingDraft.data;

  if (!batches || !batches.length) {
    return null;
  }

  return batches.find(batch => batch._id === selectedBatch);
};

export const getSelectedFiling = (state) => {
  if (!(state && state.filingDraft)) {
    return null;
  }

  const { data } = state.filingDraft;
  const batch = getFilingBatch(state);
  if (!(data.selectedFiling && batch && batch.items && batch.items.length)) {
    return null;
  }

  return batch.items.find(item => item._id === data.selectedFiling);
};

export const isFilingDraftEditable = (state) => {
  const { data: filingDraft } = state.filingDraft;
  const { data: user } = state.user;

  const { authors } = filingDraft;

  if (!filingDraft || !authors || !user || !user._id) {
    return false;
  }

  return !!authors.find(author => author._id === user._id);
};

export const isFilingDraftItemEditable = (state, item) => {
  const filing = getSelectedFiling(state) || item;
  if (filing && (filing.status === FILING_STATUS.DRAFT || filing.status === FILING_STATUS.SUBMITTED)) {
    return false;
  }

  const filingDraft = state && state.filingDraft ? state.filingDraft.data : null;
  const user = state.user.data;

  const authors = getFilingDraftAuthors(filingDraft, filing);

  if (!filingDraft || !authors || !user || !user._id) {
    return false;
  }

  return !!authors.find(author => author._id === user._id);
};

export const getCurrentUserId = (state) => state.user?.data?._id;

export const getNonArchivedUsers = (state) => state.users?.filter(user => !user.archived);
