import axios from 'axios';
import { setAuthToken } from './index';

const URL = `${process.env.REACT_APP_API_ENDPOINT}/mozart`;

export const GET_MOZART_PROJECTS = 'GET_MOZART_PROJECTS';
export const GET_MOZART_PROJECTS_SUCCESS = 'GET_MOZART_PROJECTS_SUCCESS';
export const GET_MOZART_PROJECTS_ERROR = 'GET_MOZART_PROJECTS_ERROR';

export const GET_MOZART_FORMS_PROJECT = 'GET_MOZART_FORMS_PROJECT';
export const GET_MOZART_FORMS_PROJECT_SUCCESS = 'GET_MOZART_FORMS_PROJECT_SUCCESS';
export const GET_MOZART_FORMS_PROJECT_ERROR = 'GET_MOZART_FORMS_PROJECT_ERROR';

export const IMPORT_TEMPLATE_MOZART_FORMS = 'IMPORT_TEMPLATE_MOZART_FORMS';
export const IMPORT_ITEM_MOZART_FORMS = 'IMPORT_ITEM_MOZART_FORMS';

export const INVALIDATE_MOZART_FORMS = 'INVALIDATE_MOZART_FORMS';

export const invalidateMozartForms = () => (dispatch) => {
  dispatch({ type: INVALIDATE_MOZART_FORMS });
}

export const getMozartForms = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${URL}/forms`, data);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const getMozartProjects = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: GET_MOZART_PROJECTS });
    try {
      const res = await axios.post(`${URL}/forms/projects`, data);
      dispatch({
        type: GET_MOZART_PROJECTS_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_MOZART_PROJECTS_ERROR,
        payload: response && response.data && response.data.message,
      });
      throw response && response.data;
    }
  };
};

export const getMozartFormsProject = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({
      type: GET_MOZART_FORMS_PROJECT,
      payload: data.projectId,
    });
    try {
      const res = await axios.post(`${URL}/forms/project`, data);
      if (res?.data) {
        dispatch({
          type: GET_MOZART_FORMS_PROJECT_SUCCESS,
          payload: {
            id: data.projectId,
            data: res.data
          },
        });
      }
    } catch ({ response }) {
      dispatch({
        type: GET_MOZART_FORMS_PROJECT_ERROR,
        payload: response?.data?.message,
      });
      throw response?.data;
    }
  };
};

export const importTemplateMozartForms = ({
  filingDraftId,
  batchId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/forms/${filingDraftId}/batches/${batchId}/template/forms`, data);
      dispatch({
        type: IMPORT_TEMPLATE_MOZART_FORMS,
        payload: {
          batchId,
          data: res.data,
        },
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const importItemMozartForms = ({
  filingDraftId,
  batchId,
  itemId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/forms/${filingDraftId}/batches/${batchId}/items/${itemId}/form/entries`, data);
      dispatch({
        type: IMPORT_ITEM_MOZART_FORMS,
        payload: {
          batchId,
          itemId,
          data: res.data,
        },
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};
