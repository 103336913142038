import axios from 'axios';
import { setAuthToken, URL } from './index';

export const GET_LATEST_UPDATES = 'GET_LATEST_UPDATES';
export const GET_LATEST_UPDATES_SUCCESS = 'GET_LATEST_UPDATES_SUCCESS';
export const GET_LATEST_UPDATES_ERROR = 'GET_LATEST_UPDATES_ERROR';

export const GET_PINNED_PROJECT = 'GET_PINNED_PROJECT';
export const GET_PINNED_PROJECT_SUCCESS = 'GET_PINNED_PROJECT_SUCCESS';
export const GET_PINNED_PROJECT_ERROR = 'GET_PINNED_PROJECT_ERROR';

export const GET_UPCOMING_DEADLINES = 'GET_UPCOMING_DEADLINES';
export const GET_UPCOMING_DEADLINES_SUCCESS = 'GET_UPCOMING_DEADLINES_SUCCESS';
export const GET_UPCOMING_DEADLINES_ERROR = 'GET_UPCOMING_DEADLINES_ERROR';

export const getLatestUpdates = (productIds = []) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: GET_LATEST_UPDATES });
    try {
      const res = await axios.get(`${URL}/dashboard/latest-updates`, {
        params: {
          limit: 100,
          productIds,
        },
      });
      dispatch({
        type: GET_LATEST_UPDATES_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_LATEST_UPDATES_ERROR,
        payload: response.data,
      });
      throw response.data;
    }
  };
};

export const getPinnedProject = () => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: GET_PINNED_PROJECT });
    try {
      const res = await axios.get(`${URL}/dashboard/pinned-project`);
      dispatch({
        type: GET_PINNED_PROJECT_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_PINNED_PROJECT_ERROR,
        payload: response.data,
      });
      throw response.data;
    }
  };
};

export const getUpcomingDeadlines = (productIds = []) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: GET_UPCOMING_DEADLINES });
    try {
      const res = await axios.get(`${URL}/dashboard/upcoming-deadlines`, {
        params: {
          limit: 100,
          productIds,
        },
      });
      dispatch({
        type: GET_UPCOMING_DEADLINES_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_UPCOMING_DEADLINES_ERROR,
        payload: response && response.data,
      });
      throw response && response.data;
    }
  };
};
