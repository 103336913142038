import { ReactComponent as DashboardIcon } from '../assets/dashboard.svg';
import { ReactComponent as FilingDraftIcon } from '../assets/filing-drafts.svg';
import { ReactComponent as FilingIcon } from '../assets/projects.svg';
import { ReactComponent as ObjectionIcon } from '../assets/objections.svg';
import { ReactComponent as Library } from '../assets/library.svg';
import { ReactComponent as Drive } from '../assets/drive.svg';
import { ReactComponent as Reporting } from '../assets/reporting.svg';
import { ReactComponent as AnalyticsIcon } from '../assets/project-analytics.svg';
import { ReactComponent as Organizer } from '../assets/organizer.svg';

export const PERMISSION = {
  NONE: 'NONE',
  READ: 'READ',
  WRITE: 'WRITE',
};

export const PERMISSION_DISPLAY = {
  NONE: 'None',
  READ: 'Read Only',
  WRITE: 'Read / Write',
};

export const PERMISSION_COLOR = {
  NONE: 'red',
  READ: 'orange',
  WRITE: 'green',
};

export const PERMISSION_DROPDOWN_LIST = Object
  .keys(PERMISSION)
  .map(key => ({
    label: PERMISSION_DISPLAY[key],
    value: key,
  }));

export const getPermissionList = (permissions = []) => {
  if (!permissions.length) {
    return PERMISSION_DROPDOWN_LIST;
  }

  return permissions.map(permission => ({
    label: PERMISSION_DISPLAY[permission],
    value: PERMISSION[permission],
  }));
};

export const getRoleEntityList = (hasProjectStatusFeature) => [
  {
    name: 'Dashboard',
    key: 'DASHBOARD',
    icon: <DashboardIcon />,
    permissions: getPermissionList(['NONE', 'WRITE']),
    children: [
      {
        name: 'Latest Updates',
        parent: 'DASHBOARD',
        key: 'DASHBOARD_LATEST_UPDATES',
        permissions: getPermissionList(['NONE', 'WRITE']),
        children: [],
      },
      {
        name: 'Upcoming Deadlines',
        parent: 'DASHBOARD',
        key: 'DASHBOARD_UPCOMING_DEADLINES',
        permissions: getPermissionList(['NONE', 'WRITE']),
        children: [],
      },
      {
        name: 'Pinned Project',
        parent: 'DASHBOARD',
        key: 'DASHBOARD_PINNED_PROJECT',
        permissions: getPermissionList(['NONE', 'WRITE']),
        children: [],
      },
      {
        name: 'Notifications',
        parent: 'DASHBOARD',
        key: 'DASHBOARD_NOTIFICATIONS',
        permissions: getPermissionList(['NONE', 'WRITE']),
        children: [],
      },
      {
        name: 'Reminders',
        parent: 'DASHBOARD',
        key: 'DASHBOARD_REMINDERS',
        permissions: getPermissionList(['NONE', 'WRITE']),
        children: [],
      },
    ],
  },
  {
    name: 'Filings',
    icon: <FilingIcon />,
    key: 'FILINGS',
    permissions: getPermissionList(['NONE', 'WRITE']),
    children: [
      {
        name: 'Workspace',
        parent: 'FILINGS',
        key: 'FILINGS_WORKSPACES',
        permissions: getPermissionList(['READ', 'WRITE']),
        children: [],
      },
      {
        name: 'Product',
        parent: 'FILINGS',
        key: 'FILINGS_PRODUCTS',
        permissions: getPermissionList(['READ', 'WRITE']),
        children: [],
      },
      {
        name: 'Project',
        parent: 'FILINGS',
        key: 'FILINGS_PROJECTS',
        permissions: getPermissionList(['READ', 'WRITE']),
        children: [
          ...hasProjectStatusFeature
            ? [{
              name: 'Draft Project',
              parent: 'FILINGS',
              key: 'FILINGS_PROJECT_DRAFT_STATUS',
              permissions: getPermissionList(['READ', 'WRITE']),
              children: [],
            },
            {
              name: 'Active Project',
              parent: 'FILINGS',
              key: 'FILINGS_PROJECT_ACTIVE_STATUS',
              permissions: getPermissionList(['READ', 'WRITE']),
              children: [],
            },
            {
              name: 'Closed Project',
              parent: 'FILINGS',
              key: 'FILINGS_PROJECT_CLOSED_STATUS',
              permissions: getPermissionList(['READ', 'WRITE']),
              children: [],
            },
            ] : [],
          {
            name: 'Chat',
            parent: 'FILINGS',
            key: 'FILINGS_PROJECT_CHAT',
            permissions: getPermissionList(),
            children: [],
          },
        ],
      },
      {
        name: 'State',
        parent: 'FILINGS',
        key: 'FILINGS_STATE',
        permissions: getPermissionList(),
        children: [],
      },
    ],
  },
  {
    name: 'Filing',
    icon: <FilingIcon />,
    key: 'FILINGS_FILING',
    permissions: getPermissionList(),
    children: [
      {
        name: 'Search',
        parent: 'FILINGS_FILING',
        key: 'SEARCH',
        permissions: getPermissionList(['NONE', 'WRITE']),
        children: [],
      },
      {
        name: 'Chat',
        parent: 'FILINGS_FILING',
        key: 'FILINGS_FILING_CHAT',
        permissions: getPermissionList(),
        children: [],
      },
      {
        name: 'Reminders',
        parent: 'FILINGS_FILING',
        key: 'FILINGS_FILING_REMINDERS',
        permissions: getPermissionList(),
        children: [],
      },
    ],
  },
  {
    name: 'Filing Drafts',
    key: 'FILING_DRAFTS',
    icon: <FilingDraftIcon />,
    permissions: getPermissionList(),
    children: [
      {
        name: 'Chat',
        parent: 'FILING_DRAFTS',
        key: 'FILING_DRAFTS_CHAT',
        permissions: getPermissionList(),
        children: [],
      },
      {
        name: 'Reminders',
        parent: 'FILING_DRAFTS',
        key: 'FILING_DRAFTS_REMINDERS',
        permissions: getPermissionList(),
        children: [],
      },
    ],
  },
  {
    name: 'Objections',
    key: 'OBJECTIONS',
    icon: <ObjectionIcon />,
    permissions: getPermissionList(),
    children: [
      {
        name: 'Chat',
        parent: 'OBJECTIONS',
        key: 'OBJECTIONS_CHAT',
        permissions: getPermissionList(),
        children: [],
      },
      {
        name: 'Reminders',
        parent: 'OBJECTIONS',
        key: 'OBJECTIONS_REMINDERS',
        permissions: getPermissionList(),
        children: [],
      },
    ],
  },
  {
    name: 'Library',
    key: 'LIBRARY',
    icon: <Library />,
    permissions: getPermissionList(),
    children: [],
  },
  {
    name: 'Drive',
    key: 'DRIVE',
    icon: <Drive />,
    permissions: getPermissionList(['NONE', 'WRITE']),
    children: [],
  },
  {
    name: 'Reporting',
    key: 'REPORTING',
    icon: <Reporting />,
    permissions: getPermissionList(['NONE', 'WRITE']),
    children: [],
  },
  {
    name: 'Analytics',
    key: 'ANALYTICS',
    icon: <AnalyticsIcon />,
    permissions: getPermissionList(['NONE', 'WRITE']),
    children: [],
  },
  {
    name: 'Organizer',
    key: 'ORGANIZER',
    icon: <Organizer />,
    permissions: getPermissionList(['NONE', 'WRITE']),
    children: [],
  },
];
