import { combineReducers } from 'redux';
import authReducer from './AuthReducer';
import dashboardReducer from './DashboardReducer';
import productReducer from './ProductReducer';
import projectReducer from './ProjectReducer';
import projectsReducer from './ProjectsReducer';
import stateDetailsReducer from './StateDetailsReducer';
import formsLibraryReducer from './FormsLibraryReducer';
import ratesLibraryReducer from './RatesLibraryReducer';
import accountsReducer from './AccountsReducer';
import usersReducer from './UsersReducer';
import serffContactsReducer from './SerffContactsReducer';
import serffAuthorsReducer from './SerffAuthorsReducer';
import serffCompaniesReducer from './SerffCompaniesReducer';
import instancesReducer from './InstancesReducer';
import currentUserReducer from './CurrentUserReducer';
import searchReducer from './SearchReducer';
import WorkflowReducer from './WorkflowReducer';
import ChatReducer from './ChatReducer';
import RemindersReducer from './RemindersReducer';
import workspacesReducer from './WorkspacesReducer';
import FormDetailsReducer from './FormDetailsReducer';
import RatesRulesDetailsReducer from './RatesRulesDetailsReducer';
import CustomFieldsReducer from './CustomFieldsReducer';
import FilingDraftsReducer from './FilingDraftsReducer';
import FilingDraftRulesReducer from './FilingDraftRulesReducer';
import FilingDraftReducer from './FilingDraftReducer';
import CompaniesReducer from './CompaniesReducer';
import FilingReducer from './FilingReducer';
import ApiClientsReducer from './ApiClientsReducer';
import ResponseDraftsReducer from './ResponseDraftsReducer';
import AmendmentDraftsReducer from './AmendmentDraftsReducer';
import NoteDraftsReducer from './NoteDraftsReducer';
import PostSubmissionDraftsReducer from './PostSubmissionDraftsReducer';
import IntegrationsReducer from './IntegrationsReducer';
import MozartReducer from './MozartReducer';
import SyncReducer from './SyncReducer';
import LibraryForm from './LibraryFormReducer';
import LibraryRate from './LibraryRateReducer';
import AttachmentComparisonReducer from './AttachmentComparisonReducer';
import AiAssistantReducer from './AiAssistantReducer';
import DriveReducer from './DriveReducer';
import AnalyticsReducer from './AnalyticsReducer';
import RolesReducer from './RolesReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  user: currentUserReducer,
  companies: CompaniesReducer,
  accounts: accountsReducer,
  users: usersReducer,
  serffContacts: serffContactsReducer,
  serffAuthors: serffAuthorsReducer,
  serffCompanies: serffCompaniesReducer,
  instances: instancesReducer,
  roles: RolesReducer,
  products: productReducer,
  project: projectReducer,
  projects: projectsReducer,
  filing: FilingReducer,
  filingDraft: FilingDraftReducer,
  filingDrafts: FilingDraftsReducer,
  filingRules: FilingDraftRulesReducer,
  dashboard: dashboardReducer,
  workspaces: workspacesReducer,
  stateDetails: stateDetailsReducer,
  search: searchReducer,
  formsLibrary: formsLibraryReducer,
  ratesLibrary: ratesLibraryReducer,
  formDetails: FormDetailsReducer,
  ratesRulesDetails: RatesRulesDetailsReducer,
  workflow: WorkflowReducer,
  responseDrafts: ResponseDraftsReducer,
  amendmentDrafts: AmendmentDraftsReducer,
  noteDrafts: NoteDraftsReducer,
  postSubmissionDrafts: PostSubmissionDraftsReducer,
  chat: ChatReducer,
  libraryForm: LibraryForm,
  libraryRate: LibraryRate,
  reminders: RemindersReducer,
  customFields: CustomFieldsReducer,
  apiClients: ApiClientsReducer,
  integrations: IntegrationsReducer,
  mozartForms: MozartReducer,
  syncStatus: SyncReducer,
  attachmentComparisonQueue: AttachmentComparisonReducer,
  aiAssistant: AiAssistantReducer,
  drive: DriveReducer,
  analytics: AnalyticsReducer,
});

export default rootReducer;
